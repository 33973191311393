











import axios from "axios";
import Vue from "vue";

export default Vue.extend({
  name: "FilterDropdown",
  props: {
    filterURL: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  created() {
    this.getFilter();
  },
  data() {
    return {
      filterOptions: {},
      finishedLoading: false,
    };
  },
  methods: {
    getFilter(): void {
      const config = {
        params: {
          format: "json",
        },
      };
      axios
        .get(this.filterURL, config)
        .then((response) => {
          this.filterOptions = response.data["results"];
        })
        .catch(() => {
          // console.log(error.message);
        });
    },
  },
});
