











































import Vue from "vue";
import { API_URL_CHAIRS } from "@/config";
import Feed from "@/components/general/Feed.vue";
import ChairCard from "@/components/chair/ChairCard.vue";
import FilterDropdown from "@/components/general/FilterDropdown.vue";

export default Vue.extend({
  name: "Chairs",
  components: { Feed, ChairCard, FilterDropdown },
  data() {
    return {
      API_URL_CHAIRS,
    };
  },
});
