









































































import Vue, { PropType } from "vue";
import type { Chair } from "@/types";

export default Vue.extend({
  name: "ChairCard",
  props: {
    chair: {
      type: Object as PropType<Chair>,
      required: true,
    },
  },
});
