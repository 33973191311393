var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card item-card"},[_c('b-row',{staticClass:"h-100"},[_c('b-col',{attrs:{"sm":4}},[_c('router-link',{attrs:{"to":'chairs/' + _vm.chair.inventoryNumber}},[_c('b-card-img',{staticClass:"item-card__thumbnail",class:{
            'item-card__thumbnail--no-img-available':
              !_vm.$helpers.isImageAvailable(_vm.chair).available,
          },attrs:{"src":_vm.$helpers.getImageSrc(_vm.chair),"alt":"Chair image"}})],1)],1),_c('b-col',{attrs:{"sm":8}},[_c('div',{staticClass:"item-card__text"},[_c('router-link',{attrs:{"to":'chairs/' + _vm.chair.inventoryNumber}},[_c('b-card-title',[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.chair.name || _vm.chair.type.name))+" ")])],1),_c('p',[_vm._v(_vm._s(_vm.$helpers.getDesigners(_vm.chair.designers)))]),(_vm.chair.type)?_c('b-button',{staticClass:"pill-button",attrs:{"variant":"info","pill":""},on:{"click":function($event){return _vm.$emit('update-filter', {
              filter: 'type',
              pk: _vm.chair.type.pk,
              name: _vm.chair.type.name,
            })}}},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.chair.type.name)))]):_vm._e(),(_vm.chair.material)?_c('b-button',{staticClass:"pill-button",attrs:{"variant":"info","pill":""},on:{"click":function($event){return _vm.$emit('update-filter', {
              filter: 'material',
              pk: _vm.chair.material.pk,
              name: _vm.chair.material.name,
            })}}},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.chair.material.name))+" ")]):_vm._e(),(_vm.chair.period)?_c('b-button',{staticClass:"pill-button",attrs:{"variant":"info","pill":""},on:{"click":function($event){return _vm.$emit('update-filter', {
              filter: 'period',
              pk: _vm.chair.period.pk,
              name: _vm.chair.period.name,
            })}}},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.chair.period.name))+" ")]):_vm._e()],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }