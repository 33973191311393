var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('Feed',{attrs:{"itemsUrl":_vm.API_URL_CHAIRS},scopedSlots:_vm._u([{key:"filter-bar",fn:function(ref){
var updateFilter = ref.updateFilter;
return [_c('FilterDropdown',{staticClass:"filter-dropdown",attrs:{"filterURL":"https://collecties.bk.tudelft.nl/api/chairtypes","name":"Type"},on:{"update":function($event){return updateFilter('type', $event)}}}),_c('FilterDropdown',{staticClass:"filter-dropdown",attrs:{"filterURL":"https://collecties.bk.tudelft.nl/api/materials","name":"Material"},on:{"update":function($event){return updateFilter('material', $event)}}}),_c('FilterDropdown',{staticClass:"filter-dropdown",attrs:{"filterURL":"https://collecties.bk.tudelft.nl/api/periods","name":"Period"},on:{"update":function($event){return updateFilter('period', $event)}}})]}},{key:"items",fn:function(ref){
var items = ref.items;
var updateFilter = ref.updateFilter;
return [_c('b-row',_vm._l((items),function(item){return _c('b-col',{key:item.pk,staticClass:"chair-item",attrs:{"lg":"6"}},[_c('ChairCard',{attrs:{"chair":item},on:{"update-filter":function($event){return updateFilter($event.filter, {
                pk: $event.pk,
                name: $event.name,
              })}}})],1)}),1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }