









































import Vue from "vue";

export default Vue.extend({
  name: "FilterBar",
  data() {
    return {
      searchQuery: "",
    };
  },
});
