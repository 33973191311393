





































import Vue from "vue";
import axios from "axios";
import FilterBar from "./FilterBar.vue";

export default Vue.extend({
  name: "Feed",
  components: { FilterBar },
  props: {
    itemsUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      currentPage: 1,
      itemsPerPage: 18,
      totalCount: undefined,
      isLoading: true,
      errorMessage: "",
      searchQuery: "",
      filters: {} as Record<string, { pk: string; name: string }>,
    };
  },
  created() {
    this.searchQuery = this.$route.query.search
      ? this.$route.query.search.toString()
      : "";
    this.loadItems();
  },

  methods: {
    updateFilter(name: string, value: { pk: string; name: string }) {
      Vue.set(this.filters, name, value);
      this.changePage(1);
    },
    deleteFilter(name: string) {
      Vue.delete(this.filters, name);
      this.changePage(1);
    },
    changePage(page: number) {
      this.currentPage = page;

      // Scroll to top on feed page change
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.loadItems();
    },
    searchItems(query: string) {
      this.searchQuery = query;
      this.changePage(1);
    },
    async loadItems() {
      if (
        this.searchQuery !== this.$route.query.search &&
        !(this.searchQuery === "" && this.$route.query.search === undefined)
      ) {
        const query = {
          ...this.$route.query,
          search: this.searchQuery || undefined,
        };
        await this.$router.replace({ query });
      }

      const filtersPK: Record<string, string> = {};

      for (const [key, value] of Object.entries(this.filters)) {
        filtersPK[key] = value.pk;
      }

      this.isLoading = true;
      const config = {
        params: {
          page: this.currentPage,
          page_size: this.itemsPerPage,
          search: this.searchQuery,
          format: "json",
          ...filtersPK,
        },
      };
      axios
        .get(this.itemsUrl, config)
        .then((response) => {
          this.items = response.data["results"];
          this.totalCount = response.data["count"];
          this.isLoading = false;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          this.isLoading = false;
        });
    },
  },
});
